// current page

function parseUri(str) {
  var o = parseUri.options,
    m = o.parser[o.strictMode ? "strict" : "loose"].exec(str),
    uri = {},
    i = 14;

  while (i--) uri[o.key[i]] = m[i] || "";

  uri[o.q.name] = {};
  uri[o.key[12]].replace(o.q.parser, function ($0, $1, $2) {
    if ($1) uri[o.q.name][$1] = $2;
  });

  return uri;
}

parseUri.options = {
  strictMode: false,
  key: [
    "source",
    "protocol",
    "authority",
    "userInfo",
    "user",
    "password",
    "host",
    "port",
    "relative",
    "path",
    "directory",
    "file",
    "query",
    "anchor",
  ],
  q: {
    name: "queryKey",
    parser: /(?:^|&)([^&=]*)=?([^&]*)/g,
  },
  parser: {
    strict:
      /^(?:([^:\/?#]+):)?(?:\/\/((?:(([^:@]*)(?::([^:@]*))?)?@)?([^:\/?#]*)(?::(\d*))?))?((((?:[^?#\/]*\/)*)([^?#]*))(?:\?([^#]*))?(?:#(.*))?)/,
    loose:
      /^(?:(?![^:@]+:[^:@\/]*@)([^:\/?#.]+):)?(?:\/\/)?((?:(([^:@]*)(?::([^:@]*))?)?@)?([^:\/?#]*)(?::(\d*))?)(((\/(?:[^?#](?![^?#\/]*\.[^?#\/.]+(?:[?#]|$)))*\/?)?([^?#\/]*))(?:\?([^#]*))?(?:#(.*))?)/,
  },
};

let pathname = window.location.pathname;
let menuElem = document.querySelectorAll("header nav a:not(.logo)");
let menuElemFooter = document.querySelectorAll("footer a:not(.box-logo)");

menuElem.forEach((el) => {
  if (parseUri(el.getAttribute("href")).path == pathname) {
    el.classList.add("current-page");
  }
  if (
    parseUri(el.getAttribute("href")).path.includes("account") &&
    pathname == "/account/"
  ) {
    el.classList.add("current-page");
  }
});

menuElemFooter.forEach((el) => {
  if (parseUri(el.getAttribute("href")).path == pathname) {
    el.classList.add("current-page");
  }
});

// end current page

document.querySelector(".default_field_nickname")
  ? document
      .querySelector(".default_field_nickname")
      .addEventListener("keyup", (e) => {
        document.querySelector(".default_field_username").value =
          e.target.value;
      })
  : null;

if (document.querySelector(".login-remember")) {
  let elementAfter = document.createElement("a");
  elementAfter.setAttribute("href", "/password-reset/");
  elementAfter.classList.add("forgot-pass");
  elementAfter.textContent = "Forgot password?";

  document.querySelector(".login-remember").after(elementAfter);
}

// animation

function animateFrom(elem, direction) {
  direction = direction || 1;
  var x = 0,
    y = direction * 100;
  if (elem.classList.contains("gs_reveal_fromLeft")) {
    x = -200;
    y = 0;
  } else if (elem.classList.contains("gs_reveal_fromRight")) {
    x = 200;
    y = 0;
  }
  elem.style.transform = "translate(" + x + "px, " + y + "px)";
  elem.style.opacity = "0";
  elem.classList.add("isActive");

  gsap.fromTo(
    elem,
    { x: x, y: y, autoAlpha: 0 },
    {
      duration: 1.25,
      x: 0,
      y: 0,
      autoAlpha: 1,
      ease: "expo",
      overwrite: "auto",
    }
  );
}

//test
function hide(elem) {
  gsap.set(elem, { autoAlpha: 0 });
  elem.classList.remove("isActive");
}

document.addEventListener("DOMContentLoaded", function () {
  gsap.registerPlugin(ScrollTrigger);

  gsap.utils.toArray(".gs_reveal").forEach(function (elem) {
    //  hide(elem); // assure that the element is hidden when scrolled into view

    ScrollTrigger.create({
      trigger: elem,
      onEnter: function () {
        animateFrom(elem);
      },
      onEnterBack: function () {
        animateFrom(elem, -1);
      },
      onLeave: function () {
        hide(elem);
      }, // assure that the element is hidden when scrolled into view
    });
  });
});

// gs_reveal ipsType_center
// gs_reveal gs_reveal_fromLeft
// gs_reveal gs_reveal_fromRight

// hide <a href="/password-reset/" title="Password Lost and Found." hidden="">Lost your password?</a> on login page
document
  .querySelectorAll('[title="Password Lost and Found."]')
  .forEach((el) => {
    el.setAttribute("hidden", "");
  });

// CURSOR
var cursor = $(".cursor");

var posX = 0,
  posY = 0;

var mouseX = 0,
  mouseY = 0;

TweenMax.to({}, 0.016, {
  repeat: -1,
  onRepeat: function () {
    posX += (mouseX - posX) ;
    posY += (mouseY - posY) ;

    TweenMax.set(cursor, {
      css: {
        left: mouseX,
        top: mouseY,
      },
    });
  },
});

// change input submit to button submit

let allButton = document
  .querySelectorAll("input[type='submit']")
  .forEach((el) => {
    let newButton = document.createElement("button");
    newButton.setAttribute("type", "button");
    // newButton.classList.add("um-button");
    // newButton.classList.add("um-button");
    el.classList.forEach((elem) => {
      newButton.classList.add(elem);
    });
    newButton.innerText = el.getAttribute("value");

    el.style.cssText =
      "height: 0; width: 0; padding: 0; margin: 0; transition: 0s; display: none;";

    newButton.addEventListener("click", () => {
      el.click();
    });

    el.parentNode.insertBefore(newButton, el);
    // el.parentNode.append(newButton);
  });

// end change input submit to button submit

$(document).on("mousemove", function (e) {
  mouseX = e.clientX;
  mouseY = e.clientY;
});
// yellow circle
$("a, .button").on("mouseenter", function () {
  cursor.addClass("active");
});
$("a, .button").on("mouseleave", function () {
  cursor.removeClass("active");
});

// button animation
document.querySelectorAll(".button").forEach((button) => {
  button.innerHTML =
    "<div><span>" +
    button.textContent.split("").join("</span><span>") +
    "</span></div>";
  button
    .querySelectorAll("span")
    .forEach((el) =>
      el.textContent.trim() == "" ? el.classList.add("empty") : null
    );
});
// end button animation

// button animation
document.querySelectorAll(".policy-page .title").forEach((button) => {
  button.innerHTML =
    "<div><span>" +
    button.textContent.split("").join("</span><span>") +
    "</span></div>";
  button
    .querySelectorAll("span")
    .forEach((el) =>
      el.textContent.trim() == "" ? el.classList.add("empty") : null
    );
});
// end button animation

$(".our-advantages .cirlce").mouseenter(function () {
  $(this).find(".circle-small").attr("paused", "true");

  $(this)
    .find(".circle-small")
    .on("animationiteration webkitAnimationIteration", function () {
      if ($(this).attr("paused") === "true") {
        $(this).css("animation-play-state", "paused");
      }
    });
});

$(".our-advantages .cirlce").mouseleave(function () {
  $(this).find(".circle-small").css("animation-play-state", "running");
  $(this).find(".circle-small").attr("paused", "false");
});

$(".why-us .slider_custom").slick({
  infinite: true,
  slidesToShow: 1,
  slidesToScroll: 1,
  dots: false,
  // appendDots: ".dots-container",
  arrows: true,
  prevArrow: $(".why-us .prev-slide"),
  nextArrow: $(".why-us .next-slide"),
});

let dataTitle = "";
document.addEventListener("DOMContentLoaded", function () {
  if (document.querySelectorAll("form.wppb-user-forms ul li")) {
    // задаємо актрибу "data-title" нашим полям
    document.querySelectorAll("form.wppb-user-forms ul li").forEach((el) => {
      dataTitle != "" ? el.setAttribute("data-title", dataTitle) : null;
      el.classList.contains("wppb-heading")
        ? (dataTitle = el.textContent)
        : null;
    });

    // при наитискані на кастомну кнопку змінювати контент
    document.querySelectorAll(".acc-header button").forEach((el) => {
      el.addEventListener("click", () => {
        dataTitle = el.getAttribute("data-title");

        if (dataTitle == "Delete Account") {
          document.querySelector(".wppb-delete-account").click();
        } else {
          document.querySelectorAll(`li[data-title]`).forEach((e) => {
            e.classList.remove("show");
            e.getAttribute("data-title") == dataTitle
              ? e.classList.add("show")
              : null;
          });

          document.querySelectorAll(".acc-header button").forEach((el) => {
            el.classList.remove("current");
          });
          el.classList.add("current");
        }
      });
    });

    document.querySelector(".acc-header button")?.click();
  }
});

if (document.querySelector("input#old_pass")) {
  document.querySelector("#old_pass").setAttribute("type", "password");
  document.querySelector("#old_pass").value = "";
  document.querySelector("#passw1").setAttribute("disabled", "");
  document.querySelector("#passw2").setAttribute("disabled", "");
}

document.addEventListener("DOMContentLoaded", function () {
  if (document.querySelector("input#old_pass")) {
    document.querySelector("input#old_pass").addEventListener("change", (e) => {
      jQuery.post(
        `${document.location.origin}/wp/wp-admin/admin-ajax.php?action=chekc_old_pass`,
        {
          action: "chekc_old_pass",
          user_id: user_id,
          old_pass: document.querySelector("#old_pass").value,
        },
        function (response) {
          if (response == true) {
            document.querySelector("#passw1").removeAttribute("disabled");
            document.querySelector("#passw2").removeAttribute("disabled");
          } else {
            console.log("error");
            console.log(response);
            document.querySelector("#passw1").setAttribute("disabled", "");
            document.querySelector("#passw2").setAttribute("disabled", "");
          }
        }
      );
    });
  }
});

// show-hide password
let allPasswords = document.querySelectorAll("input[type=password]");

let eays;
allPasswords.forEach((element) => {
  let parent = element.parentNode;
  let wrapper = document.createElement("div");
  wrapper.classList.add("input-password-parent");

  parent.replaceChild(wrapper, element);
  wrapper.appendChild(element);

  element.parentNode.style.position = "relative";
  eays = document.createElement("button");
  eays.setAttribute("type", "button");
  eays.classList.add("show-hide");
  eays.innerHTML = `<svg width="22" height="10" viewBox="0 0 22 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M1 9.36786C3.32558 -2.74001 20.5349 -0.634295 21 8.84143" stroke="#000"/>
                      <circle cx="11.5" cy="7.5" r="2.5" fill="#000"/>
                    </svg>
                <div class="password-line off">
                  <svg width="26" height="14" viewBox="0 0 26 14" fill="#212942" xmlns="http://www.w3.org/2000/svg">
                    <path d="M1.93469 13C3.36023 12.019 4.79217 11.0915 6.35934 10.3407C9.49128 8.84034 12.232 7.65174 15.2979 6.02005C16.6476 5.30173 18.4132 4.34126 19.7125 3.53937C20.6154 2.98217 21.5272 2.49591 22.5232 2.11885C22.9563 1.95487 23.3801 1.77018 23.8123 1.60466C23.885 1.57678 23.9788 1.50201 25.0001 1" stroke="#212942" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                  </svg>
                </div>`;
  element.parentNode.append(eays);
});

// show-hide password
document.querySelectorAll("input[type=password]")?.forEach((el) => {
  el.parentNode.querySelector(".show-hide")?.addEventListener("click", () => {
    if (el.getAttribute("type") === "password") {
      el.setAttribute("type", "text");
    } else {
      el.setAttribute("type", "password");
    }

    el.parentNode.querySelector(".password-line")?.classList.toggle("show");
    el.parentNode.querySelector(".password-line")?.classList.toggle("off");
  });
});
// end show-hide password

const removeShow = () => {
  document.querySelectorAll("button[data-id]").forEach((el) => {
    el.classList.remove("active");
  });

  document.querySelectorAll(".main_content").forEach((el) => {
    el.classList.remove("show");
  });
};

document
  .querySelectorAll(".services-page .button-transparent, .pricing-page .button-transparent")
  .forEach((el) => {
    let elText = el.textContent.replaceAll(" ", "-").toLowerCase();
    el.setAttribute("data-id", elText);

    el.addEventListener("click", (e) => {
      removeShow();

      localStorage.setItem("data-id", elText);

      document.querySelectorAll(`[data-id=${elText}]`).forEach((elem) => {
        elem.classList.add("active");
      });

      document.querySelector(`#${elText}`)
        ? document.querySelector(`#${elText}`).classList.add("show")
        : null;
    });
  });

if (localStorage.getItem("data-id")) {
  let elText = localStorage.getItem("data-id");
  document.querySelector(`[data-id=${elText}]`)
    ? document.querySelector(`[data-id=${elText}]`).click()
    : null;
}

if (parseUri(window.location).anchor != "") {
  document
    .querySelector(`[data-id=${parseUri(window.location).anchor}]`)
    .click();
}
